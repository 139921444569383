Card.Title {
    background-color: lightgreen;
}

.container1.log{
display: flex;
justify-content: center;
margin-top: 20px;
}

#one1{
    background-color:  #52796f;
    outline-color: #52796f;
    border-color: #52796f;
}

.card.bg-Success{
    margin: 20px 0px 20px 0px;
    align-content: center;
}

.CardContainer{
    display: flex;
    align-content: center;
    justify-content: space-evenly;
}

.cards{
    min-width: 1080px;
    align-items: center;
}

.plantlist {
    margin: 0 auto;
}

.logbuttons {
    border: none;
    border-radius: 10px;
    text-align: center;
    padding: 0px 50px 0px 50px;
    background-color: #2f3e46;
    color: white;
    margin-bottom: 20px;
}

.logbuttons:hover {
    transform: scale(1.05);
}

.plantlist{
    display: flex;
    flex-direction: column;
    align-items: center;
}