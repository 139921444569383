.container .login{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 2rem;
}

.Create-account,.Login {
    display: flex;
    flex-direction: column;
    box-shadow: 1rem;
    align-items: center;
}

.Login .info{
    padding-block: 1px;
    
}
label{
    padding-right: .2rem;
}

.wrapper img{
    height: 15rem; width: 15rem;
}

.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#noacc{
    max-width: 300px;
}

form{
    margin-bottom: 10px;
}

footer{
    align-items: center;
    justify-content: center;
    text-align: center;
}

h1 {
    font-family: "Quicksand";
}