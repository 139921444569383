.container.info {
    display: flex;
    flex-direction: column;
}
h1 {
    font-size: 50px;
    text-align: center;
}
.title {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: "Quicksand";
}

.plant-pic {
    width: 200px;
}

.non-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 100px;
    margin: 0px 0px 50px 0px;
    font-family: "Raleway";
}

.description {
    text-align: justify;
    text-justify: inter-word;
    background-color: #84a98c;
    padding: 20px;
    border-radius: 20px;
}

img {
    border-radius: 20px;
}

h3 {
    font-family: "Quicksand";
}