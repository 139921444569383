* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
  .container {
    text-align: center;
  }

  .container1{
    display: flex;
    font-family: "Quicksand";
    
     font-weight: 800;
      line-height: 72px;
       margin: 0 0 24px;
        text-align: center; 
  text-transform: uppercase;
  color: #2f3e46;

  }

 a img{
    margin-left: 10px;
  }
  
ul{
  font-family: "Quicksand";
    
    font-size: 20px;
     font-weight: 700;
      line-height: 72px;
       margin: 0 0 24px;
        text-align: center; 
  text-transform: uppercase;
  color: #2f3e46;
  height: 100px;
}

li{
    padding-inline: 10px;
}

  .nav {
    background-color: #f8fff3;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
    height: 100px;
  }
  
  .site-title {
    font-size: 2rem;
  }
  
  .nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  .nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
  }
  
  .nav li.active {
    background-color: #555;
  }
  
  .nav li:hover {
    background-color: #777;
  }

  