
.table {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0px 10px 0px;
    
}

.reminderbuttons {
    border: none;
    border-radius: 10px;
    text-align: center;
    padding: 0px 50px 0px 50px;
    background-color: #2f3e46;
    color: white;
    margin-bottom: 20px;
}
.calendar {
    margin: 30px;
    padding: 0px 50px 0px 50px;
    background: #cad2c5;
    border-radius: 50px;
}
td {
    width: 100px;
    height: 100px;
    text-align: right;
    align-items: top;
    font-family: "Raleway";
}
.month {
    margin: 20px 0px 20px 0px;
    font-family: "Quicksand";
}

.watering-day {
    background-color: #094217;
    color: white;
    border-radius: 5px;
    text-align: center;
}

thead {
    font-family: "Quicksand";
    text-align: center;
}
