

.col .title{
     font-family: "Quicksand";
      font-size: 62px;
       font-weight: 800;
        line-height: 72px;
         margin: 0 0 24px;
          text-align: center; 
    text-transform: uppercase;
    color: #2f3e46;
}

.subtitle{
    color: #2f3e46; font-family: "Quicksand"; font-size: 30px; font-weight: 400; line-height: 36px; margin: 0 0 24px; text-align: center;
}

#buttons{
    color: #2f3e46;
}

.background{
    background-image: linear-gradient(to bottom right, #cad2c5, #84a98c, #52796f);
}

.container.home{
    height: 700px;
    max-width: 1920px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-blend-mode: multiply;
    background-image: url(https://cdn.discordapp.com/attachments/989877566099832866/1066582487670718524/logo2finfin.png);
    
}

.col{
    flex-grow: 1;
    flex-shrink: 0;
    padding: 100px;
}